<template>
  <v-data-table :items="items" :headers="headers" :server-items-length="itemTotal" :options="options"  :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" :loading="loading" loading-text="Loading scans..." @update:options="(val) => $emit('update', val)">
    <template v-slot:item.inDate="{ item }">{{ stringFormatDate(item.in.date) }}</template>
    <template v-slot:item.outDate="{ item }">{{ item.out && item.out.date ? stringFormatDate(item.out.date) : (item.status === 'R' ? '***No Clock-out***' : '**Clocked-in ' + getTimeDiff(new Date(), item.in.date)) }}</template>
    <template v-slot:item.hours="{ item }">{{ formatHours(item.hours, item.in.date) }}</template>
    <template v-slot:item.status="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ item.status === 'P' && !('out' in item) ? 'I' : item.status }}</span>
        </template>
        <span v-if="item.status === 'R'">Rejected: {{ item.reason }}</span>
        <span v-else-if="item.status === 'C'">Completed (time entered into Banner)</span>
        <span v-else-if="!('out' in item)">Incomplete (has not yet clocked out)</span>
        <span v-else-if="item.status === 'P'">Pending (has not been loaded into Banner)</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import { stringFormatDate } from '../../helpers/formatters'
import { getTimeDiff } from '../../helpers/diffs'
import { ref } from '@vue/composition-api'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    itemTotal: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: []
        }
      }
    }
  },
  setup () {
    const headers = ref([
      { text: 'Card ID', value: 'prox' },
      { text: 'Name', value: 'name' },
      { text: 'In Date', value: 'inDate' },
      { text: 'In Location', value: 'in.timeclock.shortName' },
      { text: 'Out Date', value: 'outDate' },
      { text: 'Out Location', value: 'out.timeclock.shortName' },
      { text: 'Shift Length', value: 'hours' },
      { text: 'Status', value: 'status' }
    ])

    function formatHours (hours) {
      if (hours == null) return ''
      const minutes = Math.floor((hours % 1) * 60)
      hours = Math.floor(hours)
      let time = []
      if (hours > 0) {
        time.push(hours + ' hrs')
      }
      if (minutes > 0) {
        if (minutes % 5 === 0) {
          time.push(minutes + ' min')
        } else {
          const rounded = Math.round(minutes / 5) * 5
          time.push(minutes + ' (' + rounded + ') min')
        }
      }
      return time.join(', ')
    }

    return {
      headers,
      formatHours,
      stringFormatDate,
      getTimeDiff
    }
  }
}
</script>
